<template>
    <div class="Coursestatistics clearfix">
        <div class="coudet_top fl">
            <div class="div_titles fl">课程统计</div>
            <el-tooltip class="item" effect="dark" content="返回上一层" placement="bottom-start">
                <i class="iconfonts icons-back" @click="back()"></i>
            </el-tooltip>
        </div>
        <div class="coudetail_rs fl" ref="htmlImg">
            <div class="coutical">
                <div class="coutical_l">
                    <div class="coutical_l_top">
                        <p class="clearfix _l_top">
                            <span class="fl _l_top_one">
                                <i class="iconfonts icons-banji"></i>
                            </span>
                            <span class="fl _l_top_text">
                                开班情况
                            </span>
                        </p>
                        <ul class="Report">
                            <li class="Report_one">
                                <span>开课报告</span>
                            </li>
                            <li class="Report_two clearfix">
                                <span class="fl Report_two_l">
                                    实训名称
                                </span>：
                                <span class="fr Report_two_r">
                                    {{courser.name}}
                                </span>
                            </li>
                            <li class="Report_two clearfix">
                                <span class="fl Report_two_l">
                                    班级
                                </span>：
                                <span class="fr Report_two_r">
                                    {{courser.grade}}
                                </span>
                            </li>
                            <li class="Report_two clearfix">
                                <span class="fl Report_two_l">
                                    开课时间
                                </span>：
                                <span class="fr Report_two_r">
                                    {{courser.start}}
                                </span>
                            </li>
                            <li class="Report_two clearfix">
                                <span class="fl Report_two_l">
                                    实训时间
                                </span>：
                                <span class="fr Report_two_r">
                                    {{courser.start}} ~ {{courser.end}}
                                </span>
                            </li>
                            <li class="Report_two clearfix">
                                <span class="fl Report_two_l">
                                    参加人数
                                </span>：
                                <span class="fr Report_two_r">
                                    {{courser.users}}人
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="coutical_l_bottom">
                        <p class="list_p">学生名单</p>
                        <ul class="list_ul">
                            <li class="list_ul_li" v-for="(item,index) in teamList" :key="index">
                                <title class="_li_title">{{item.name}}</title>
                                <ul class="list_ul_ul clearfix">
                                    <li class="list_ul_ul_li fl" v-for="(citem,cindex) in item.users" :key="cindex">
                                        <span>{{citem.name}}</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="coutical_r">
                    <div class="coutical_r_top clearfix">
                        <p class="clearfix _l_r_top">
                            <span class="fl _l_top_two">
                                <i class="iconfonts icons-pingfen1"></i>
                            </span>
                            <span class="fl _l_top_text">
                                学生评比
                            </span>
                        </p>
                        <div class="bingz_tu fl" id="main">

                        </div>
                        <div class="zhuz_tu fr" ref="chartes">

                        </div>
                    </div>
                    <div class="coutical_r_bott clearfix">
                        <div class="_r_bott_l fl">
                            <p class="clearfix _l_top">
                                <span class="fl _l_top_three">
                                    <i class="iconfonts icons-shujufenxi"></i>
                                </span>
                                <span class="fl _l_top_text">
                                    课程数据
                                </span>
                            </p>
                            <div class="data_div clearfix">
                                <div class="data_div_left fl">
                                    <p class="le_ps">草图</p>
                                    <div class="le_imgs">
                                        <span class="li_span_img">
                                            <i class="el-icon-zoom-in" @click="dk_Dt()"></i>
                                        </span>
                                        <img :src="ruleForm.p1.value" alt="">
                                    </div>
                                </div>
                                <div class="data_div_right fr">
                                    <el-form :model="ruleForm"  ref="ruleForm" class="demo-ruleForm" label-width="96px" size="small" @submit.native.prevent>
                                        <el-form-item label="现状：" prop="name">
                                            <el-col :span="23">
                                                <el-input type="textarea" v-model="ruleForm.p2.value" disabled></el-input>
                                            </el-col>
                                        </el-form-item>
                                        <el-form-item label="问题：" prop="name">
                                            <el-col :span="23">
                                                <el-input type="textarea" v-model="ruleForm.p3.value" disabled></el-input>
                                            </el-col>
                                        </el-form-item>
                                        <el-form-item label="创意（想法）：" prop="name">
                                            <el-col :span="23">
                                                <el-input type="textarea" v-model="ruleForm.p4.value" disabled></el-input>
                                            </el-col>
                                        </el-form-item>
                                        <el-form-item label="创新（想法）：" prop="name">
                                            <el-col :span="23">
                                                <div class="zui_three">
                                                    <ul class="zui_three_ul">
                                                        <li class="zui_three_ul_li" v-for="(item,index) in ruleForm.p5.value" :key="index">
                                                            <div class="zui_three_div clearfix">   
                                                                <span class="fl">热度为{{item.level}}颗星的：</span>
                                                                <span class="fl">
                                                                    <el-rate
                                                                        v-model="item.level"
                                                                        disabled
                                                                        :colors="['#ff9900','#ff9900','#ff9900']">
                                                                    </el-rate>
                                                                </span>
                                                                <!-- <i class="el-icon-star-on" v-for="(citem,cindex) in item.level" :key="cindex"></i> -->
                                                            </div>
                                                            <ul class="icon_ul clearfix">
                                                                <li class="zui_ul_li_item fl" v-for="(items3,indesx3) in item.values" :key="indesx3">
                                                                    {{items3.name}}
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </el-col>
                                        </el-form-item>
                                        <el-form-item label="方案（适应）：" prop="name">
                                            <el-col :span="23">
                                                <el-input type="textarea" v-model="ruleForm.p6.value" disabled></el-input>
                                            </el-col>
                                        </el-form-item>
                                        <p style="font-size:12px;line-height:30px;">行动（分工）：</p>
                                        <el-col :span="11">
                                            <el-form-item :label="ruleForm.p7.value.role1.title" label-width="80px" prop="name">
                                                <el-input v-model="ruleForm.p7.value.role1.name" disabled></el-input>
                                            </el-form-item>
                                            </el-col>
                                            <el-col :span="11">
                                                <el-form-item :label="ruleForm.p7.value.role2.title" label-width="80px" prop="name">
                                                    <el-input v-model="ruleForm.p7.value.role2.name" disabled></el-input>
                                                </el-form-item>
                                            </el-col>
                                        <el-col :span="11">
                                                <el-form-item :label="ruleForm.p7.value.role3.title" label-width="80px" prop="name">
                                                    <el-input v-model="ruleForm.p7.value.role3.name" disabled></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="11">
                                                <el-form-item :label="ruleForm.p7.value.role4.title" label-width="80px" prop="name">
                                                    <el-input v-model="ruleForm.p7.value.role4.name" disabled></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="11">
                                                <el-form-item :label="ruleForm.p7.value.role5.title" label-width="80px" prop="name">
                                                    <el-input v-model="ruleForm.p7.value.role5.name" disabled></el-input>
                                                </el-form-item>
                                            </el-col>
                                    </el-form>
                                </div>
                            </div>
                        </div>
                        <div class="_r_bott_r fr">
                            <p class="clearfix _l_top">
                                <span class="fl _l_top_four">
                                    <i class="iconfonts icons-dianping"></i>
                                </span>
                                <span class="fl _l_top_text">
                                    教师点评
                                </span>
                            </p>
                            <div class="_bott_div">
                                <p class="_bott_div_p clearfix">
                                    <span class="_bott_div_sp_l fl">
                                        课堂表现积极性：
                                    </span>
                                    <span class="_bott_div_sp_r fl">
                                        {{score.top}}
                                    </span>
                                </p>
                                <p class="_bott_div_p clearfix">
                                    <span class="_bott_div_sp_l fl">
                                        作业完成度：
                                    </span>
                                    <span class="_bott_div_sp_r fl">
                                        {{score.pos}}
                                    </span>
                                </p>
                                <p class="_bott_div_p clearfix">
                                    <span class="_bott_div_sp_l fl">
                                        作品评分：
                                    </span>
                                </p>
                                <ul class="_bott__ul clearfix">
                                    <li v-for="(item,index) in score.team" :key="index" class="fl clearfix">
                                        <span>
                                            {{item.name}}：
                                        </span>
                                        <span class="_bott_ul_span">
                                            {{item.score}}
                                        </span>分
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Mini_popup">
                <el-dialog
                    title="查看大图"
                    :visible.sync="innerVisible"
                    width="600px"
                    :before-close="handleClose"
                    :show-close="false"
                >
                    <i class="el-icon-close closei" @click="closeis()"></i>
                    <div class="caotu_img">
                        <img :src="imgUrl">
                    </div>
                </el-dialog>
            </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import html2canvas from 'html2canvas'
export default {
    components: {
        html2canvas
    },
    data() {
        return {
            innerVisible:false,
            imgUrl:'',
            pieList:[],
            zztList:{
                cyxy:[],
                gnsy:[],
                kxxg:[],
                legend:[],
                xAxis:[]
            },
            courser:{},
            score:{
                top:'',
                pos:'',
                team:[]
            },
            teamList:[],
            ruleForm:{
                p1:{},
                p2:{},
                p3:{},
                p4:{},
                p5:{
                    value:{
                        values:[]
                    }
                },
                p6:{},
                p7:{
                    value:{
                        role1:{},
                        role2:{},
                        role3:{},
                        role4:{},
                        role5:{}
                    }
                }
            }
        }
    },
    methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.axios.projectstatis({
                token:this.$store.state.token,
                id:this.calId
            }).then(res=>{
                if(res.data.code==0){
                    this.courser = res.data.data.course  //课程详情信息
                    this.zztList = res.data.data.bar     //柱状图信息
                    this.pieList = res.data.data.pie     //饼状图信息
                    this.ruleForm  = res.data.data.s9      //课程数据信息
                    this.teamList = res.data.data.team   //小组成员信息
                    this.score = res.data.data.score     //教师点评信息
                    this.mycharts()
                    this.Bmychart()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        dk_Dt(){
            this.imgUrl = this.ruleForm.p1.value
            this.innerVisible = true
        },
        handleClose(done){

        },
        closeis(){
            this.innerVisible = false
        },
        Bmychart(){
            var cChart = echarts.init(document.getElementById('main'));
            this.myChart2 = cChart
            cChart.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter:'{a} <br/>{b} : {c}票 ({d}%)',
                    axisPointer: {
                        type: 'shadow',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                    position: function(pt) { //提示框的位置
                        return [pt[0], 30];
                    }
                },
                legend:{
                    orient: 'vertical',
                    top:'40%',
                    left:'70%'
                },
                series:[
                    {
                        name:'票数统计',
                        type:'pie',
                        radius:'50%',
                        center:['40%','50%'],
                        data:this.pieList,
                        color: ['#55AAFF','#FFC000','#26D096'],
                        itemStyle:{
                            emphasis:{
                                shadowBlur:10,
                                shadowOffserX:0,
                                shadowColor:'#333'
                            }
                        },
                        labelLine: {
                            normal: {
                                show: false   // show设置线是否显示，默认为true，可选值：true ¦ false
                            }
                        },
                        label: {
                            normal: {
                                position: 'inner',  // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
                                // formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
                                // {a}指series.name  {b}指series.data的name
                                // {c}指series.data的value  {d}%指这一部分占总数的百分比
                                formatter: '{c}'
                            }
                        }
                    }
                ]
            })
        },
        // 柱状图
        mycharts(){
            let mysChart =echarts.init(this.$refs.chartes);
            this.myChart1 = mysChart
            mysChart.setOption(
                {
                    color:['#54AAD8','#FFD25B','#FF805B'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                            position: function(pt) { //提示框的位置
                            return [pt[0], 30];
                        }
                    },
                     grid: { //图表和父盒子之间的距离
                        show: true,
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        top:'17%',
                        containLabel: true
                    },
                    legend: {//图例
                        top:'5%',
                        data: [
                            {
                                name:'创意新颖',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                            {
                                name: '功能实用',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            },
                            {
                                name: '可行性高',
                                textStyle: {
                                    color: '#333',
                                    fontSize:14
                                }
                            }
                        ]
                    },
                    xAxis:{ //x轴
                        type: 'category',
                        // boundaryGap: false,
                        data: this.zztList.xAxis,
                        axisLabel: { 
                            interval:0,
                            textStyle: {
                            color: '#333',
                                fontSize :10
                            },
                            margin:8
                        },
                        axisLine:{
                            show :true,
                            // symbol: ['none', 'arrow'],
                            lineStyle:{
                                color:'rgb(2,121,253)'
                            }
                        },
                        axisTick:{
                            show :false
                        },
                        splitLine:{
                            show:false
                        }
                    },
                    yAxis:{
                        type: 'value',
                        axisLabel: { //x轴的坐标文字
                            show: true,
                            textStyle: {
                                color: '#333' //文字的颜色
                            }
                        },
                        max: function (value) {
                            return Math.ceil(value.max + value.max * 0.1);
                        },
                        axisLine:{
                            show :true,
                            lineStyle:{
                                color:'rgb(2,121,253)'
                            }
                        },
                        axisTick:{
                            show :false,
                        },
                        splitLine:{  //坐标在grid区域的分割线
                        　  lineStyle: { //设置分割线的样式(图表横线颜色)
                                color: ['#153a8a']
                            }
                        }
                    },
                    series: [
                        {
                            name: '创意新颖',
                            type: 'bar', //柱状图
                            data: this.zztList.cyxy,
                            barWidth :'10%', //柱状体的宽度
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14
                                        }
                                    }
                                }
                            }
                        },
                        {
                            name: '功能实用',
                            type: 'bar',
                            data: this.zztList.gnsy,
                            barWidth :'10%',
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14
                                        }
                                    }
                                }
                            }  
                        },
                        {
                            name: '可行性高',
                            type: 'bar',
                            data: this.zztList.kxxg,
                            barWidth :'10%',
                            itemStyle:{
                                normal:{
                                    label:{
                                        show:true,
                                        position:'top',
                                        textStyle:{
                                            color:'#333',
                                            fontSize:14
                                        }
                                    }
                                }
                            }
                        }
                    ]
                }
            )
        },
        back(){
            // html2canvas(this.$refs.htmlImg).then(canvas=>{
            //     let dataURL = canvas.toDataURL("image/png");
            //     this.uImgUrl = dataURL; 
            //     console.log(this.uImgUrl)
            // })
            this.$router.push({name:'Coursedetails',query:{calId:this.calId,num:2}})
        }
    },
    mounted() {
        this.getapp()
        window.onresize = () => {
            this.myChart1.resize();
            this.myChart2.resize();
        };
    }
}
</script>
<style scoped>
@import './../../assets/css/teacher/Coursestatistics.css';
</style>
<style>
.data_div_right .el-form-item__label{
    font-size: 12px;
}
.data_div_right .el-form-item--mini.el-form-item, .data_div_right .el-form-item--small.el-form-item{
    margin-bottom: 10px;
}
.data_div_right .el-textarea.is-disabled .el-textarea__inner{
    color: #333;
    background: #fff;
    cursor: default;
}
.data_div_right .el-input.is-disabled .el-input__inner{
    color: #333;
    background: #fff;
    cursor: default;
}
.Mini_popup .el-dialog{
    margin-top: 8vh!important;
}
.Mini_popup .el-dialog__body{
    padding: 20px 0px 10px;
}
.Mini_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.Mini_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color:#fff;
}
</style>